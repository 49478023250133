import React from "react";
import Hand from "./Hand.png";
import Training from "./Training.png";

export default function Why() {
  return (
    <div className="lg:px-40 px-8 py-36 pt-10 bg-[#ebfaee]">
      <div className="lg:flex lg:gap-32 lg:items-center">
        <div>
          {" "}
          <p className="lg:text-[60px] text-[30px] font-bold ">
            Why Learn <br /> Tech Skills
          </p>
        </div>
        <div className="lg:w-2/4 mt-4 lg:mt-0">
          <p>
            Learning tech skills opens up numerous career opportunities and
            ensures job security in a rapidly evolving job market. It empowers
            individuals to innovate, solve complex problems, and contribute to
            technological advancements. Additionally, tech proficiency enhances
            adaptability and versatility, making you a valuable asset across
            various industries.
          </p>
        </div>
      </div>
      <div className="mt-28">
        <h1 className="font-medium text-center text-[40px]">
          Why Learn at Code Campus?
        </h1>
        <div className="lg:flex lg:justify-between lg:gap-44 mt-10">
          <div>
            <p className="font-bold">Structured, Intensive Training</p>
            <div className="flex gap-6 items-center mt-4">
              <img src={Training} width={100} />
              <p>
                We have predefined goals, schedules and methodologies,
                curriculum design, instructional materiala, interactive
                activities and timetable.
              </p>
            </div>
          </div>
          <div className="mt-10 lg:mt-0">
            <p className="font-bold">Comprehensive Development</p>
            <div className="flex gap-6 items-center mt-4">
              <img src={Hand} width={100} />
              <p>
                We give access to experienced instructors, hands-on projects,
                and networking opportunities, ensuring comprehensive learning
                and professional growth.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
