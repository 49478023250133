import React from "react";
import Hero from "./Hero.png";
import logo from "./logo.png";
export default function Herosection() {
  return (
    <div className="lg:flex lg:justify-between block lg:px-11 px-8 bg-[#1CBB42] py-4 lg:items-center">
      <div>
        <img src={logo} width={100} />
        <h3 className="lg:text-[84px] text-[40px] p-0 mt-8 font-bold text-white">
          Unlock Your <br />
          Tech Potential
        </h3>
        <p className="mt-4 lg:text-[30px] text-[24px] text-white">
          Join Our Fast-Track <br />
          Tech Bootcamp.
        </p>

        <a href="#form">
          <button className="bg-white p-2 mt-4 rounded-lg">Get started</button>
        </a>
      </div>
      <div>
        <img
          src={Hero}
          width={600}
          className="lg:mt-0 mt-8 lg:w-[600px] w-[800] -mb-4"
        />
      </div>
    </div>
  );
}
