import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import woman from "./woman.png";
import Ellipse from "./Ellipse.svg";

export default function Form() {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userInput, setUserInput] = useState({
    firstName: "",
    lastName: "",
    email: "",
    age: "",
    phoneNumber: "",
    info: "",
  });

  const handleChange = (e) => {
    setUserInput({ ...userInput, [e.target.name]: e.target.value });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm("service_gfurt7k", "template_k1lvl8f", formRef.current, {
        publicKey: "F50JkrX2CfQlMkunh",
      })
      .then(
        () => {
          setShowModal(true);
          setUserInput({
            firstName: "",
            lastName: "",
            email: "",
            age: "",
            phoneNumber: "",
            info: "",
          });
        },
        (error) => {
          alert("Failed to send message, please try again.");
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="bg-[#ebfaee] p-12" id="form">
      <p className="text-center font-bold text-[28px]">Ready to get started?</p>
      <div className="flex items-center mx-auto justify-center mt-8">
        <form ref={formRef} onSubmit={sendEmail}>
          <div className="lg:flex lg:justify-between lg:gap-11 lg:items-center">
            <div>
              <div>
                <label>First Name</label>
                <input
                  value={userInput.firstName}
                  type="text"
                  className="bg-gray-50 border lg:w-80 w-60 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="John"
                  name="firstName"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="mt-8">
                <label>Email</label>
                <input
                  value={userInput.email}
                  type="text"
                  className="bg-gray-50 border lg:w-80 w-60 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="johndaniel@gmail.com"
                  name="email"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="mt-8">
                <label>How did you hear about us</label>
                <input
                  value={userInput.info}
                  type="text"
                  className="bg-gray-50 border lg:w-80 w-60 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Facebook"
                  name="info"
                  required
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              <div className="">
                <label>Last Name</label>
                <input
                  value={userInput.lastName}
                  type="text"
                  className="bg-gray-50 border lg:w-80 w-60 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Daniel"
                  name="lastName"
                  required
                  onChange={handleChange}
                />
              </div>

              <div className="mt-8">
                <label>Phone Number</label>
                <input
                  value={userInput.phoneNumber}
                  type="text"
                  className="bg-gray-50 border lg:w-80 w-60 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="234768746466"
                  name="phoneNumber"
                  required
                  onChange={handleChange}
                />
              </div>

              <div className="mt-8">
                <label>Age</label>
                <input
                  value={userInput.age}
                  type="text"
                  className="bg-gray-50 border lg:w-80 w-60 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="28"
                  name="age"
                  required
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <button className="bg-[#1CBB42] hover:bg-[#1CBB42] text-white font-bold py-2 px-4 mt-8 rounded">
            Apply
          </button>
        </form>
      </div>
      {showModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity duration-300 ease-in-out">
          <div className="relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center mx-auto">
            <div className="w-96 bg-white rounded-lg shadow-md px-8 py-10">
              <h2 className="text-xl font-bold text-center mb-4">Thank You!</h2>
              <p className="text-gray-700 mb-6">
                Your form has been submitted successfully.
              </p>
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="bg-[#1CBB42] hover:bg-[#1CBB42] text-white font-bold py-2 px-4 rounded"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-end -mr-12 -mb-12">
        <img src={woman} width={400} />
      </div>
    </div>
  );
}
