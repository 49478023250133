import React from "react";
import logo from "./logo.png";
import LinkedIn from "./LinkedIn.svg";
import Twitter from "./Twitter.svg";
import Facebook from "./Facebook.svg";
import Instagram from "./Instagram.svg";

export default function Footer() {
  return (
    <div className="bg-[#1CBB42] lg:p-11 p-6 block justify-center lg:flex lg:justify-between ">
      <img src={logo} width={100} />
      <div className="flex gap-8 items-center">
        <a href="https://www.instagram.com/codecampusng/">
          <img src={Instagram} width={30} />
        </a>
        <img src={Twitter} width={28} />
        <a href="https://web.facebook.com/search/top?q=code%20campus">
          <img src={Facebook} width={30} />
        </a>
        <a href="https://www.linkedin.com/company/codecampusng">
          <img src={LinkedIn} width={30} />
        </a>
      </div>
    </div>
  );
}
