import React from "react";
import Joseph from "./Joseph.png";
import Fatima from "./Fatima.png";
import Dokubo from "./Dokubo.png";

const Items = [
  {
    name: "John Smith",
    image: Joseph,
    text: "Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam euturpis molestie, dictum est a, mattis tellus. Sed dignissim, metusnec fringilla accumsan, risus sem sollicitudin lacus, Horem ipsumdolor sit amet, consectetur adipiscing elit. Etiam eu turpismolestie, dictum est a, mattis tellus. Sed dignissim, metus necfringilla accumsan, risus sem sollicitudin lacus",
  },
];
export default function Testimonials() {
  return (
    <div className="my-20 mx-14">
      <p className="text-center font-bold lg:text-[42px] text-[30px]">
        What our <span className="text-[#1CBB42]">Students</span> are saying
        about us.
      </p>
      <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-32 gap-y-12 mt-12">
        <div className="w-80">
          <div className="flex gap-2 items-center">
            {" "}
            <img src={Joseph} width={80} />
            <p className="font-bold">
              Joseph Daniel <br />
              <span className="font-medium">Full-stack Developer</span>
            </p>
          </div>
          <p className="mt-6">
            My joining Code Campus International was as a result of my request
            and hunger towards building and developing myself in technology. It
            was a great experience joining Code Campus International. I learned
            a lot while undergoing my software engineering program with Code
            Campus International. I gathered a lot of knowledge throughout my
            program of studies with them as I scaled through my study I was able
            to make a lot of achievements and success which has impacted me
            towards my goal in making software engineering my future career.
          </p>
        </div>
        <div className="w-80">
          <div className="flex gap-2 items-center">
            {" "}
            <img src={Fatima} width={80} />
            <p className="font-bold">
              Fatima Muhammed <br />
              <span className="font-medium">Front-End Developer</span>
            </p>
          </div>
          <p className="mt-6">
            The program's structured curriculum, hands-on projects, and expert
            mentorship helped me gain a deep understanding of specific
            programming. The instructors were knowledgeable, supportive, and
            always available to help. Thanks to Code Campus, I've gained the
            confidence and skills to tackle complex coding challenges and pursue
            my dream career in tech.
          </p>
        </div>
        <div className="w-80">
          <div className="flex gap-2 items-center">
            {" "}
            <img src={Dokubo} width={80} />
            <p className="font-bold">
              Joseph Dokubo
              <br />
              <span className="font-medium">Front-End Developer</span>
            </p>
          </div>
          <p className="mt-6">
            I recently had the pleasure of attending Code Campus, and I can
            confidently say it was one of the most enriching experiences of my
            life. The comprehensive curriculum and the hands-on approach to
            learning allowed me to rapidly develop my coding skills and gain a
            deep understanding of software development.
          </p>
        </div>
      </div>
    </div>
  );
}
