import React from "react";
import image from "./image.jpg";
import mobile from "./mobile.jpg";

export default function Banner() {
  return (
    <div>
      <div
        style={{ backgroundImage: `url(${image})` }}
        className="lg:bg-cover lg:h-screen lg:bg-no-repeat lg:relative hidden lg:block"
      >
        <div className="lg:absolute lg:top-48 text-center lg:px-80 px-12">
          {" "}
          <p className="font-bold text-white lg:text-[38px] text-[30px]">
            What we have achieved so far
          </p>
          <p className="text-white">
            In the past three years, we have graduated over 100 proficient
            software developers, cybersecurity experts, and data scientists who
            are now working at top organizations, solving complex problems
            globally.
          </p>
        </div>
      </div>
      {/* Mobile view */}
      <div
        style={{ backgroundImage: `url(${mobile})` }}
        className="bg-cover h-auto bg-no-repeat lg:relative lg:hidden"
      >
        <div className="lg:absolute lg:top-48 text-center lg:px-80 px-12 py-32">
          {" "}
          <p className="font-bold text-white lg:text-[38px] text-[30px]">
            What we have achieved so far
          </p>
          <p className="text-white">
            In the past three years, we have graduated over 100 proficient
            software developers, cybersecurity experts, and data scientists who
            are now working at top organizations, solving complex problems
            globally.
          </p>
        </div>
      </div>
      <div className=" bg-[#ebfaee]">
        <div className="p-24 lg:px-80 px-20">
          <p className="text-center font-bold text-[32px]">
            We are presently <br />
            <span className=" bg-yellow-400">enlisted on Course Report.</span>
          </p>
          <p className="mt-4">
            Course Report is a website that provides information about coding
            bootcamps and tech training programs. It offers reviews, ratings,
            and detailed information about various bootcamps, helping
            prospective students find and compare different coding schools to
            make informed decisions about their education. The website also
            features articles, interviews, and resources related to the tech
            industry and career development.
          </p>
        </div>
      </div>
    </div>
  );
}
