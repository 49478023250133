import Herosection from "./component/Herosection/Herosection";
import Why from "./component/Why/Why";
import Banner from "./component/Banner/Banner";
import Testimonials from "./component/Testimonials/Testimonials";
import Form from "./component/Form/Form";
import Footer from "./component/Footer/Footer";

function App() {
  return (
    <div>
      <Herosection />
      <Why />
      <Banner />
      <Testimonials />
      <Form />
      <Footer />
    </div>
  );
}

export default App;
